import { Vector3, Euler } from "three";
import { StoryboardType } from "../../../types";

export const storyboard = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    if (i < 2) {
      storyboardArr.push({
        _id: i,
        img: ``,
        allowControls: false,
        loop: true,
        video: `${appAssets}/elbow/zoomToElbow.mp4`,
        bg: "#454545",
      });
    } else {
      storyboardArr.push({
        _id: i,
        img: ``,
        allowControls: false,
        loop: true,
        bg: "#454545",
        pos: new Vector3(0, 0, 0),
        rot: new Euler(0, 0, 0),
        scale: 50,
        hotspots: [],
        video: `${appAssets}/elbow/zoomToElbow.mp4`,
      });
    }
  }

  return storyboardArr;
};
