import { StoryboardType } from "../../../types";

export const arthropathy = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 0,
    img: `${appAssets}/elbow/synovitis/zoom0Level1/Elbow_SynInternal_Zoom1_0000.jpg`,
    allowControls: false,
  });
  return storyboardArr;
};
