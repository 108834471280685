import styled from "styled-components";

const gradientOne = "linear-gradient(165deg, #fcb813 0%, #ffa719 80%)";

const gradientTwo =
  "linear-gradient(90deg, rgba(50,50, 50, 1) 0%, rgba(235, 148, 0, 1) 50%)";

const gradientEloctaOne = "linear-gradient(322deg, #00467f 0%,  #00467f 100%)";

const gradientAlprolixOne =
  "linear-gradient(322deg, #1e246d 0%,  #1e246d 100%)";

export const themeColors = {
  brand: {
    primary: "#fcb813",
    secondary: "#00BCE4",
    menuHoverText: "#000",
    menuNoHoverText: "#000",
    invert: "invert(1)",
    link: "#000",
  },
  font: { h6: "1rem", p: "0.8rem", sub: "0.6rem" },
  white: { primary: "#fff" },
  grey: {
    primary: "#666",
    light: "#bbb",
    dark: "#585854",
    black: "#000",
    navBar: "#585854",
  },
  gradient: { primary: gradientOne, secondary: gradientTwo },
};

export const themeColorsElocta = {
  brand: {
    primary: "#00467f",
    secondary: "#00BCE4",
    menuHoverText: "#fff",
    menuNoHoverText: "#fff",
    invert: "invert(0)",
    link: "#fff",
  },
  font: { h6: "1rem", p: "0.8rem", sub: "0.6rem" },
  white: { primary: "#fff" },
  grey: {
    primary: "#666",
    light: "#bbb",
    dark: "#585854",
    black: "#000",
    navBar: "#585854",
  },
  gradient: { primary: gradientEloctaOne, secondary: gradientTwo },
};

export const themeColorsAlprolix = {
  brand: {
    primary: "#1e246d",
    secondary: "#aac328",
    menuHoverText: "#fff",
    menuNoHoverText: "#fff",
    invert: "invert(0)",
    link: "#fff",
  },
  font: { h6: "1rem", p: "0.8rem", sub: "0.6rem" },
  white: { primary: "#fff" },
  grey: {
    primary: "#666",
    light: "#E3E9EE",
    dark: "#222",
    black: "#000",
    navBar: "#585854",
  },
  gradient: { primary: gradientAlprolixOne, secondary: gradientTwo },
};

export const FooterLogoWrapper = styled.div<{ theme: any }>`
  position: absolute;
  bottom: 0;
  width: 170px;
  margin-left: -30px;
  margin-bottom: -20px;
  left: 0px;
  background: ${(props) => props.theme.white.primary};
  padding: 20;
  transform: skewX(20deg) rotate(-8deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const H2Splash = styled.h2`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  position: absolute;
  text-align: center;
  font-size: 1rem;
`;

export const FullCentralWrapper = styled.div<{
  bg?: string;
  opacity?: number;
  slideUp?: boolean;
  zIndex?: number;
  theme?: any;
}>`
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  background: ${(props) => props.bg || "#454545"};
  transition: opacity 1s;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.slideUp ? "-200vh" : 0)};
  top: 0;
  left: 0;
  z-index: ${(props) => (props.zIndex ? props.zIndex : -1)};
  font-family: museo-sans, sans-serif;
  user-select: none;
  draggable: false !important;
`;
