import React from "react";
import { FullCentralWrapper } from "../../styles";
import splash from "../../assets/splash-1.jpeg";
import { StartButton, Fade } from "../../components";
import { Page, PageType } from "../../types";
import { ThemeContext, ContentContext } from "../../context";
import { shouldRender, shouldFadeOut } from "../../utils/fadeAndVisible";

export function Splash(props: {
  changePage: (newPage: PageType) => void;
  setDeviceType: (event: string) => void;
  isMobile: boolean;
  thisPage: Page;
  pageArray: PageType[];
  branded?: boolean;
}) {
  const { thisPage, pageArray, changePage, isMobile } = props;
  function clickStart(deviceType: string) {
    props.setDeviceType(deviceType);

    changePage({
      page: Page.Landing,
      visible: true,
      transitionFrame: 2,
      backEvent: {
        page: Page.One,
        transitionFrame: 0,
        visible: true,
        animateOut: true,
      },
    });
  }

  return (
    <Fade
      visible={!shouldFadeOut(pageArray, thisPage)}
      shouldRender={shouldRender(pageArray, thisPage)}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ContentContext.Consumer>
            {({ cmsData, editButton }) => {
              return (
                <FullCentralWrapper bg={theme.gradient.primary}>
                  <div
                    style={{
                      width: "100vw",
                      height: "100vh",
                      transition: "filter 1s",
                      backgroundImage: `url(${splash})`,
                      backgroundSize: isMobile ? "contain" : "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      opacity: 0.2,
                    }}
                  ></div>
                  <StartButton
                    onClickEvent={(event) => clickStart(event)}
                    isMobile={isMobile}
                  />
                  <div
                    style={{
                      position: "fixed",
                      bottom: 52,
                      color: "#fff",
                      left: 140,
                      fontSize: "0.8rem",
                    }}
                  >
                    {editButton("jobCode", "otherItems")}
                    {cmsData.otherItems.jobCode.label}
                  </div>
                </FullCentralWrapper>
              );
            }}
          </ContentContext.Consumer>
        )}
      </ThemeContext.Consumer>
    </Fade>
  );
}
