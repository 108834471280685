import React from "react";
import { EloctaBG } from "./styles";
import { Fade } from "../../components";
import { PageType, Page } from "../../types";
import { ThemeContext, ContentContext, HotspotContext } from "../../context";
import { RightMenuAlprolix } from "./RightMenuAlprolix";
import { Subtitle } from "./components/Subtitle";
import { shouldRender, shouldFadeOut } from "../../utils/fadeAndVisible";
import parse from "html-react-parser";

export function Alprolix(props: {
  thisPage: Page;
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray, thisPage } = props;
  const [hotspotIsOpen, setHotspotIsOpen] = React.useState(false);

  return (
    <Fade
      visible={!shouldFadeOut(pageArray, thisPage)}
      shouldRender={shouldRender(pageArray, thisPage)}
    >
      <HotspotContext.Provider
        value={{ isOpen: hotspotIsOpen, setIsOpen: setHotspotIsOpen }}
      >
        <ThemeContext.Consumer>
          {({ theme }) => (
            <ContentContext.Consumer>
              {({ cmsData, editButton }) => {
                return (
                  <EloctaBG>
                    <Subtitle
                      fileName={cmsData.subtitles.ProtectionSubtitles.file}
                      fadeOut={hotspotIsOpen}
                    />
                    <RightMenuAlprolix
                      setPage={changePage}
                      page={Page.Alprolix}
                    />
                    <sub
                      style={{
                        position: "fixed",
                        bottom: 30,
                        right: 0,
                        padding: 20,
                        width: "50vw",
                        maxWidth: 360,
                        fontWeight: "bold",
                        color: theme.brand.primary,
                      }}
                    >
                      {parse(cmsData.otherItems.alprolixDisclaimer)}
                    </sub>
                  </EloctaBG>
                );
              }}
            </ContentContext.Consumer>
          )}
        </ThemeContext.Consumer>
      </HotspotContext.Provider>
    </Fade>
  );
}
