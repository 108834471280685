import { StoryboardType } from "../../../types";

export const arthropathy = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  storyboardArr.push({
    _id: 0,
    img: `${appAssets}/knee/arthropathyHome.jpg`,
  });
  return storyboardArr;
};
