import React from "react";
import * as COMPONENTS from "../../components";
import { Page, PageType } from "../../types";
import { ThemeContext } from "../../context";
import eloctaIcon from "../../views/Elocta/assets/elocta_icon.svg";
import alprolixIcon from "../../views/Alprolix/assets/alprolix-logo.png";
import branding from "../../assets/sobi_white.svg";
import toolBranding from "../../assets/toolLogo.png";
import { FooterLogoWrapper } from "../../styles";

export function Footers(props: {
  deviceType: string;
  page: PageType[];
  cmsData: any;
  editButton: any;
  branded?: boolean;
}) {
  const { page, cmsData, editButton, branded } = props;
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <COMPONENTS.Footer
          cmsData={cmsData}
          editButton={editButton}
          page={page}
          icon={
            page[0].page === Page.Elocta || page[0].page === Page.Alprolix ? (
              <FooterLogoWrapper theme={theme}>
                <img
                  src={page[0].page === Page.Elocta ? eloctaIcon : alprolixIcon}
                  style={{
                    transform: "skewX(-20deg) rotate(8deg)",
                    marginBottom: 30,
                    width: 80,
                    marginTop: 20,
                    marginLeft: 20,
                  }}
                  alt="Logo"
                />
              </FooterLogoWrapper>
            ) : (
              <>
                <img
                  src={branding}
                  style={{
                    position: "absolute",
                    top: -54,
                    width: "100px",
                    left: 20,
                  }}
                  alt="SOBI Logo"
                />
                <img
                  src={toolBranding}
                  style={{
                    position: "absolute",
                    top: -64,
                    width: "100px",
                    right: 20,
                  }}
                  alt="SOBI Logo"
                />
                <span
                  style={{
                    position: "absolute",
                    top: -12,
                    right: 4,
                    color: "#fff",
                    fontSize: "0.6rem",
                  }}
                >
                  {`v2.0.7${branded ? "-B" : ""}`}
                </span>
              </>
            )
          }
        />
      )}
    </ThemeContext.Consumer>
  );
}
