import React from "react";
import * as PAGES from "./knee";
import { PageType } from "../types";

export function KneePages(props: {
  page: PageType[];
  changePage: (newPage: PageType) => void;
  deviceType: string;
  setDeviceType: (type: string) => void;
  loaderSobi: boolean;
  branded: boolean;
  cmsData: any;
}) {
  const { page, changePage, cmsData } = props;
  return (
    <>
      <PAGES.HealthyJoint pageArray={page} changePage={changePage} />
      <PAGES.ClinicalBleed
        pageArray={page}
        changePage={changePage}
        cmsData={cmsData}
      />
      <PAGES.AfterBleed pageArray={page} changePage={changePage} />
      <PAGES.Synovitis
        pageArray={page}
        changePage={changePage}
        cmsData={cmsData}
      />
      <PAGES.Arthropathy
        pageArray={page}
        changePage={changePage}
        cmsData={cmsData}
      />
      <PAGES.SubclinicalBleed
        pageArray={page}
        changePage={changePage}
        cmsData={cmsData}
      />
      <PAGES.AfterBleedSubclinical pageArray={page} changePage={changePage} />
      <PAGES.SynovitisSubclinical pageArray={page} changePage={changePage} />
      <PAGES.ArthropathySubclinical pageArray={page} changePage={changePage} />
      <PAGES.ProtectingJointHealth pageArray={page} changePage={changePage} />
    </>
  );
}
