import { StoryboardType } from "../../../types";

export const protectingJointHealth = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  for (let i = 0; i < maxRotation; i++) {
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/orbitMan/OrbitMan_0000.jpg`,
      bg: "#444444",
    });
  }
  return storyboardArr;
};
