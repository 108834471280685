import { StoryboardType } from "../../../types";

export const storyboardClinicalBleed = (maxRotation: number, cmsData: any) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    const frameNumber =
      i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

    if (i < 19) {
      storyboardArr.push({
        _id: i,
        img: `${appAssets}/knee/clinicalBleedAnim/KneeBleed_Clinical_${frameNumber}_Ultra.jpg`,
        subtitle: "ClinicalBleedSubtitles",
        timePeriod: {
          title: cmsData.otherItems.timescale[0],
          step: 1,
          divisible: 3,
        },
        video: `${appAssets}/elbow/clinicalBleedElbow.mp4`,
        allowControls: true,
      });
    } else {
      storyboardArr.push({
        _id: i,
        img: `${appAssets}/knee/clinicalBleedAnim/KneeBleed_Clinical_${frameNumber}_Ultra.jpg`,
        subtitle: "ClinicalBleedSubtitles",
        timePeriod: {
          title: cmsData.otherItems.timescale[2],
          step: 2,
          divisible: 4,
        },
        video: `${appAssets}/elbow/clinicalBleedElbow.mp4`,
      });
    }
  }
  return storyboardArr;
};
