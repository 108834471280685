import { StoryboardType } from "../../../types";

export const arthropathySubclinical = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/elbow/arthropathyExternal/zoom0Level1/Elbow_ArthExternal_Zoom1_0000.jpg`,
  });
  return storyboardArr;
};
