import { StoryboardType } from "../../../types";

export const arthropathyAnim = (maxRotation: number, cmsData: any) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  for (let i = 0; i < maxRotation; i++) {
    const months = i < 13;
    storyboardArr.push({
      _id: i,
      img: ``,
      subtitle: "ArthropathySubtitles",
      timePeriod: {
        title: months
          ? cmsData.otherItems.timescale[3]
          : cmsData.otherItems.timescale[4],
        step: months ? 1 : 4,
        divisible: months ? 2 : 8,
      },
      video: `${appAssets}/elbow/arthropathyElbow.mp4`,
      allowControls: true,
    });
  }
  return storyboardArr;
};
