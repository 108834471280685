import React from "react";
import resizeIcon from "../../assets/icons/resize.svg";
import "./resize.css";

interface ResizeScreenProps {
  shouldRender: boolean;
}

const ResizeScreen: React.FC<ResizeScreenProps> = ({ shouldRender }) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <div className="resize-wrapper">
      <img src={resizeIcon} alt="Resize Screen" />
    </div>
  );
};

export default ResizeScreen;
