import { Vector3, Euler } from "three";
import { StoryboardType } from "../../../types";

export const storyboard360 = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];

  for (let i = 0; i < maxRotation; i++) {
    storyboardArr.push({
      _id: i,
      img: ``,
      allowControls: false,
      loop: true,
      bg: "#454545",
      pos: new Vector3(-1.2, 0, 0),
      rot: new Euler(0, 0, 0),
      scale: 50,
      hotspots: [],
    });
  }

  return storyboardArr;
};
