import React from 'react';
import { StartButtonStyle, IconStyle } from './styles';
import desktop from '../assets/icons/desktop.svg';
import mobile from '../assets/icons/tablet.svg';
import { Row, Column } from './';
import { ContentContext } from '../context';
import { Title } from './Title';

export const StartButton = (props: {
  onClickEvent: (event: string) => void;
  onHoverEvent?: () => void;
  onMouseOut?: () => void;
  isMobile?: boolean;
}) => {
  const { onClickEvent, onHoverEvent, onMouseOut, isMobile } = props;

  return (
    <div
      style={{
        position: 'absolute',
        color: '#222',
        width: '100%',
        textAlign: 'center',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <StartButtonStyle
        onMouseOver={() => (onHoverEvent ? onHoverEvent() : null)}
        onMouseOut={() => (onMouseOut ? onMouseOut() : null)}
      >
        <ContentContext.Consumer>
          {({ cmsData, editButton }) => {
            return (
              <>
                <Title
                  title={cmsData.otherItems.chooseExperience.label}
                  editButton={editButton}
                />
                <div
                  style={{
                    borderRadius: 5,
                    paddingBottom: 10,
                  }}
                >
                  <Row a="center" j="center">
                    <Column style={{ position: 'relative' }}>
                      <IconStyle
                        onClick={() => {
                          //@ts-ignore
                          window._mtm.push({
                            event: 'customEvent',
                            eventCategory: 'deviceType',
                            eventAction: 'Desktop',
                            eventName: '',
                            siteName: 'sweden',
                          });

                          /*
                          trackEvent({
                            category: 'deviceType',
                            action: 'Desktop',
                            name: '',
                            customDimensions: [
                              {
                                id: 1,
                                value: 'sweden',
                              },
                            ],
                          });*/
                          onClickEvent('desktop');
                        }}
                        isMobile={isMobile}
                      >
                        <img
                          src={desktop}
                          alt="desktop"
                          style={{ height: '50%' }}
                        />
                      </IconStyle>
                      {editButton('desktop', 'otherItems')}

                      <h6 style={{ margin: '10px 0' }}>
                        {cmsData.otherItems.desktop.label}
                      </h6>
                    </Column>
                    <Column style={{ position: 'relative' }}>
                      <IconStyle
                        onClick={() => {
                          //@ts-ignore
                          window._mtm.push({
                            event: 'customEvent',
                            eventCategory: 'deviceType',
                            eventAction: 'Mobile',
                            eventName: '',
                            siteName: 'sweden',
                          });
                          /*
                          trackEvent({
                            category: 'deviceType',
                            action: 'Tablet',
                            name: '',
                            customDimensions: [
                              {
                                id: 1,
                                value: 'sweden',
                              },
                            ],
                          });*/
                          onClickEvent('mobile');
                        }}
                        isMobile={isMobile}
                      >
                        <img
                          src={mobile}
                          alt="mobile"
                          style={{ height: '50%' }}
                        />
                      </IconStyle>
                      {editButton('tablet', 'otherItems')}

                      <h6 style={{ margin: '10px 0' }}>
                        {cmsData.otherItems.tablet.label}
                      </h6>
                    </Column>
                  </Row>
                </div>
              </>
            );
          }}
        </ContentContext.Consumer>
      </StartButtonStyle>
    </div>
  );
};
