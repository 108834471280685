import { StoryboardType } from "../../../types";

export const storyboardAfterTheBleed = () => {
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  const storyboardArr: StoryboardType[] = [];
  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/knee/subclinicalBleed/afterBleedSub.jpg`,
    subtitle: "",
  });
  return storyboardArr;
};
