import { Vector3, Euler } from "three";
import { StoryboardType } from "../../../types";

export const storyboardClinicalBleedLanding = (
  maxRotation: number,
  cmsData: any
) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  for (let i = 0; i < maxRotation; i++) {
    const frameNumber =
      i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

    storyboardArr.push({
      _id: i,
      pos: new Vector3(0, 7 - i / 10, 0),
      rot: new Euler(0, i / 64, 0),
      scale: 1 + i / 40,
      img: `${appAssets}/knee/clinicalBleedAnim/KneeBleed_Clinical_${frameNumber}_Ultra.jpg`,
      timePeriod: {
        title: cmsData.otherItems.timescale[0],
        step: 10,
        divisible: 50,
      },
      video: `${appAssets}/knee/kneeBleed.mp4`,
      allowControls: false,
    });
  }
  return storyboardArr;
};
