import { StoryboardType } from "../../../types";

export const arthropathySubclinical = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/knee/arthropathy360external/OrbitKnee_ArthropicSkin_Zoom0_0000.jpg`,
  });
  return storyboardArr;
};
