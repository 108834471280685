import React, { useMemo } from "react";
import { Frames } from "../../components";
import { Page } from "../../types";
import {
  synovitis,
  synovitisAnim,
  synovitisPost,
  synovitisPostExternal,
} from "../../data/elbow/synovitis";
import { zoom0Level1 } from "../../data/elbow/clinicalBleed/rotation";
import { RightMenuOne } from "../../menu/RightMenuOne";
import { LeftMenuOne } from "../../menu/LeftMenuOne";
import { PageType } from "../../types";
import { getTrackImages } from "../../utils/getTrackImages";
import { Frames360New } from "../../components/Frames360New";
import { shouldRender, shouldFadeOut } from "../../utils/fadeAndVisible";
import { Fade } from "../../components";

export function Synovitis(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
  cmsData: any;
}) {
  const { changePage, pageArray, cmsData } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.Synovitis,
        pageArray[0].page === Page.Synovitis ? pageArray[0].animateOut : false,
      ],
      [
        pageArray[0].page === Page.SynovitisAnimation,
        pageArray[0].animateOut &&
          pageArray[0].page === Page.SynovitisAnimation,
      ],
      [pageArray[0].page === Page.SynovitisPostAnimation],
      [pageArray[0].page === Page.Synovitis360],
      [
        pageArray[0].page === Page.SynovitisExternal,
        pageArray[0].animateOut && pageArray[0].page === Page.SynovitisExternal,
      ],
      [pageArray[0].page === Page.Synovitis360External],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.Synovitis;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => synovitis(), changePage)}
              animateOut={memoProps[0][1]}
              storyboard={synovitis()}
              rightMenu={<RightMenuOne setPage={changePage} page={thisPage} />}
              leftMenu={<LeftMenuOne setPage={changePage} page={thisPage} />}
              id="synovitis"
              background="#444"
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.SynovitisAnimation;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => synovitisAnim(1154, cmsData),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={synovitisAnim(40, cmsData)}
              isVideo={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SynovitisAnimation}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SynovitisAnimation}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.Synovitis,
                  visible: true,
                  transitionFrame: -1,
                  animateOut: false,
                })
              }
              id="synovitis-animation"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, cmsData])}

      {useMemo(() => {
        const thisPage = Page.SynovitisPostAnimation;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => synovitisPost(), changePage)}
              animateOut={memoProps[2][1]}
              storyboard={synovitisPost()}
              background="#444"
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SynovitisPostAnimation}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SynovitisPostAnimation}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
              id="synovitis-post"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.Synovitis360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[3][0]}
              config={{
                file: "Elbow_SynInternal_Zoom",
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: "elbow/synovitis",
              }}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.Synovitis360} />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.SynovitisPostAnimation,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.SynovitisExternal;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => synovitisPostExternal(), changePage)}
              animateOut={memoProps[4][1]}
              storyboard={zoom0Level1(1)}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SynovitisExternal}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SynovitisExternal}
                />
              }
              id="synovitis-external"
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.Synovitis360External;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[5][0]}
              config={{
                file: "Elbow_SynExternal_Zoom",
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: "elbow/synovitisExternal",
              }}
              external={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.Synovitis360External}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.SynovitisExternal,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
