import React, { useState } from 'react';
import {
  RectangleButtonStyle,
  RectangleButtonSquareStyle,
  RectangleButtonWrapperStyle,
  RectangleButtonWrapperInnerStyle,
  ContentPanelStyle,
  RectangleButtonSquareInnerStyle,
  ContentPanelInnerStyle,
  ButtonIcon,
  CTA,
} from './styles';
import parse from 'html-react-parser';
import playIcon from '../../assets/icons/forward.svg';
import addIcon from '../../assets/icons/hotspotPlus.svg';
import chevronIcon from '../../assets/icons/cam.png';
import playTriangle from '../../assets/icons/play.svg';
import threeDIcon from '../../assets/icons/360.svg';
import { ThemeContext, DeviceContext, ComponentContext } from '../../context';

export const RightMenuButton = (props: {
  item: any;
  content?: string;
  references?: string[];
  isOpen?: boolean;
  index: number;
  setOpenItem?: React.Dispatch<React.SetStateAction<number>>;
  editButton?: any;
  setPage: any;
  firstOrange?: boolean;
}) => {
  const { item, isOpen, setOpenItem, index, editButton, setPage, firstOrange } =
    props;
  const contentAssets: string | undefined =
    process.env.REACT_APP_CONTENT_IMAGE_DIRECTORY;

  const { label, config } = item;
  let isWhite = true;
  let isGrey = false;
  let onClickEvent: null | (() => any) = null;
  let clickable = false;
  let icon = '';
  if (config) {
    const newPageConfig = {
      page: config.setPage,
      transitionFrame: config.transitionFrame,
      animateOut: config.animateOut,
      backEvent: config.backEvent,
      visible: config.visible,
    };
    isWhite = config.white;
    isGrey = config.grey;
    onClickEvent = config.setPage ? () => setPage(newPageConfig) : null;
    clickable = config.setPage > 0;
    icon = config.icon;
  }
  const content = item.content ? item.content[0].content[0].html_content : '';
  const [fullscreen, setFullscreen] = useState(false);

  function fullscreenFunction(setHomeButton: any) {
    setFullscreen(!fullscreen);
    setHomeButton(fullscreen);
  }
  return (
    <>
      <ComponentContext.Consumer>
        {(componentContext) => (
          <DeviceContext.Consumer>
            {(deviceType) => (
              <ThemeContext.Consumer>
                {({ theme }) => (
                  <>
                    {icon === 'cta' ? (
                      <CTA theme={theme}>
                        {editButton && editButton}
                        {label}
                      </CTA>
                    ) : (
                      <RectangleButtonWrapperStyle
                        clickable={clickable}
                        white={isWhite}
                        grey={isGrey}
                        isOpen={isOpen || false}
                        theme={theme}
                        firstOrange={firstOrange}
                      >
                        {editButton && editButton}
                        <RectangleButtonWrapperInnerStyle
                          onClick={() => {
                            if (!isOpen) {
                              //@ts-ignore
                              window._mtm.push({
                                event: 'customEvent',
                                eventCategory: 'Expand',
                                eventAction: parse(label),
                                eventName: '',
                                siteName: 'sweden',
                              });
                            }

                            return onClickEvent
                              ? onClickEvent()
                              : setOpenItem
                              ? setOpenItem(isOpen ? -1 : index)
                              : null;
                          }}
                        >
                          <RectangleButtonSquareStyle
                            white={isWhite}
                            isOn={isOpen}
                            theme={theme}
                          >
                            <RectangleButtonSquareInnerStyle
                              isOn={isOpen}
                              theme={theme}
                            >
                              {icon === 'go' ? (
                                <ButtonIcon
                                  src={playIcon}
                                  style={{ width: 32 }}
                                  alt="play"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              ) : icon === 'external' ? (
                                <ButtonIcon
                                  src={playTriangle}
                                  style={{
                                    width: 32,
                                    filter: 'invert(100%)',
                                  }}
                                  alt="external"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              ) : icon === 'play' ? (
                                <ButtonIcon
                                  src={playTriangle}
                                  style={{
                                    width: 32,
                                    padding: 4,
                                    boxSizing: 'border-box',
                                  }}
                                  alt="play"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              ) : icon === 'chevron' ? (
                                <ButtonIcon
                                  src={chevronIcon}
                                  style={{
                                    width: 32,
                                    padding: 4,
                                    boxSizing: 'border-box',
                                    filter: 'invert(0)',
                                  }}
                                  alt="chevron"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              ) : icon === '3d' ? (
                                <ButtonIcon
                                  src={threeDIcon}
                                  style={{
                                    width: 32,
                                    padding: 5,
                                    boxSizing: 'border-box',
                                    filter: 'invert(0)',
                                  }}
                                  alt="3d"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              ) : (
                                <ButtonIcon
                                  src={addIcon}
                                  style={{ width: 32 }}
                                  alt="add"
                                  theme={theme}
                                  isOn={isOpen}
                                />
                              )}
                            </RectangleButtonSquareInnerStyle>
                          </RectangleButtonSquareStyle>
                          <RectangleButtonStyle
                            clickable={clickable}
                            white={isWhite}
                            grey={isGrey}
                            isOpen={isOpen || false}
                            theme={theme}
                          >
                            {parse(label)}
                          </RectangleButtonStyle>
                        </RectangleButtonWrapperInnerStyle>
                      </RectangleButtonWrapperStyle>
                    )}

                    {content && (
                      <ContentPanelStyle
                        isOpen={isOpen || false}
                        isMobile={deviceType === 'mobile'}
                        fullscreen={
                          fullscreen ||
                          (deviceType === 'mobile' && isOpen === true)
                        }
                        onClick={() => {
                          if (!isOpen) {
                            //@ts-ignore
                            window._mtm.push({
                              event: 'customEvent',
                              eventCategory: 'Expand',
                              eventAction: parse(label),
                              eventName: '',
                              siteName: 'sweden',
                            });
                          }
                          deviceType === 'mobile' && setOpenItem
                            ? setOpenItem(-1)
                            : fullscreenFunction(
                                componentContext.setHomeButton
                              );
                        }}
                      >
                        <ContentPanelInnerStyle
                          isOpen={isOpen || false}
                          theme={theme}
                          isMobile={deviceType === 'mobile'}
                          fullscreen={fullscreen}
                          title={
                            fullscreen || (isOpen && deviceType === 'mobile')
                              ? 'Click to minimise'
                              : 'Click to expand'
                          }
                        >
                          {parse(
                            content.replaceAll('::imageurl::', contentAssets) ||
                              ''
                          )}
                        </ContentPanelInnerStyle>
                      </ContentPanelStyle>
                    )}
                  </>
                )}
              </ThemeContext.Consumer>
            )}
          </DeviceContext.Consumer>
        )}
      </ComponentContext.Consumer>
    </>
  );
};
