import { StoryboardType } from "../../../types";

export const synovitisSubclinical = () => {
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  const storyboardArr: StoryboardType[] = [];
  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/knee/synovitisorbitexternal/zoom0Level1/OrbitKnee_SynovitisSkin_Zoom1_0000.jpg`,
  });
  return storyboardArr;
};
