import { StoryboardType } from "../../../types";

export const arthropathyExternal = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  storyboardArr.push({
    _id: 0,
    img: `${appAssets}/knee/arthropathy360external/zoom0Level1/OrbitKnee_ArthropicSkin_Zoom1_0000.jpg`,
  });
  return storyboardArr;
};
