import { ImageJSONType } from "../types";

export const loadImage = (image: ImageJSONType): Promise<any> => {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = image.url;
    loadImg.onload = () => resolve(loadImg);
    loadImg.onerror = (err) => reject(err);
  });
};
