import React, { useCallback, useEffect, useState } from "react";
import { FullCentralWrapper } from "./styles";
import * as COMPONENTS from "./components";
import { Page, PageType } from "./types";
import {
  DeviceContext,
  ContentContext,
  ThemeContext,
  ComponentContext,
} from "./context";
import CMS from "./cms";
import { themeColors, themeColorsElocta, themeColorsAlprolix } from "./styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { KneePages } from "./views/KneePages";
import { AnklePages } from "./views/AnklePages";
import { ElbowPages } from "./views/ElbowPages";
import { Landing } from "./views/other/Landing";
import { Alprolix } from "./views/Alprolix";
import { Elocta } from "./views/Elocta";
import { Splash } from "./views/other/Splash";
import { HCP } from "./views/other/HCP";
import { Disclaimer } from "./views/other/Disclaimer";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { postPageView } from "./utils/matomoEvent";

const defaultPageState: PageType[] = [
  { page: Page.Hcp, visible: true, transitionFrame: 0 },
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function AppLayout() {
  //STATES
  const [page, setPage] = React.useState<PageType[]>(defaultPageState);
  const [deviceType, setDeviceType] = React.useState("");
  const [homeButtonVisible, setHomeButtonVisible] = React.useState(true);
  const [themeNumber, setThemeNumber] = React.useState<number>(0);
  const [loaderSobi, setLoaderSobi] = React.useState(true);
  const [primaryTheme, setPrimaryTheme] = React.useState(themeColors);
  const [isMobilePortrait, setIsMobilePortrait] = React.useState(false);
  const [pageSet, setPageSet] = React.useState("knee");
  const [config, setConfig] = React.useState<any>(null);
  const [dataSet, setDataSet] = React.useState<any>(null);
  const [joints, setJoints] = React.useState<{
    ankle: boolean;
    elbow: boolean;
  }>({ ankle: false, elbow: false });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { trackPageView } = useMatomo();
  //CONSTS
  const isTooSmall = useMediaQuery("(max-width:700px)");
  const isTooShort = useMediaQuery("(max-height:500px)");
  const branded: boolean | undefined =
    process.env.REACT_APP_BRANDED === "true" ? true : false;
  const showHCP: boolean | undefined =
    process.env.REACT_APP_SHOW_HCP === "true" ? true : false;
  const showDisclaimer: boolean | undefined =
    process.env.REACT_APP_SHOW_DISCLAIMER === "true" ? true : false;
  const cmsEnabled: boolean | undefined =
    process.env.REACT_APP_CMS === "true" ? true : false;

  //Hooks
  useEffect(() => {
    //Get directory of content JSON files
    setConfig({
      content: process.env.REACT_APP_JSON_CONTENT_DIRECTORY,
    });
    setPrimaryTheme(themeColors);
    //Handle screen resize event
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //Check environment variables to see if we should show the HCP or disclaimer pages
    if (showDisclaimer) {
      setPage([{ page: Page.Disclaimer, visible: true, transitionFrame: 0 }]);
    } else if (showHCP) {
      setPage([{ page: Page.Hcp, visible: true, transitionFrame: 0 }]);
    } else {
      setPage([{ page: Page.One, visible: true, transitionFrame: 0 }]);
    }
  }, [showHCP, showDisclaimer]);

  useEffect(() => {
    //FAKE LOADER: Requested by client so don't judge me. TS 12th June 23
    let interval = setInterval(() => {
      setLoaderSobi(false);
    }, 300);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    //Not going to explain this one
    if (windowDimensions.width < windowDimensions.height) {
      setIsMobilePortrait(true);
    } else {
      setIsMobilePortrait(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    //Function for handling a change between knee and ankle content
    if (!config) return;

    fetch(`${config.content}?v=1.0.7`)
      .then((res) => res.json())
      .then((data) => {
        if (!data) return;
        const mainContent = data[0].knee;
        const extraContent = data[0][pageSet];
        const brandedContent = branded ? data[0].branded : {};
        Object.assign(
          mainContent.hotspots,
          extraContent.hotspots,
          brandedContent.hotspots
        );

        Object.assign(mainContent.menu, brandedContent.menu);
        Object.assign(
          mainContent.hotspots,
          extraContent.hotspots,
          brandedContent.hotspots
        );

        Object.assign(
          mainContent.subtitles,
          extraContent.subtitles,
          brandedContent.subtitles
        );

        Object.assign(
          mainContent.footerButtons,
          extraContent.footerButtons,
          brandedContent.footerButtons
        );

        const thisContent = [
          {
            hotspots: mainContent.hotspots,
          },
          {
            menu: { ...mainContent.menu },
          },
          {
            subtitles: {
              ...mainContent.subtitles,
              ...brandedContent.subtitles,
            },
          },
          {
            leftMenu: {
              ...mainContent.leftMenu,
              ...brandedContent.leftMenu,
            },
          },
          {
            labels: {
              ...mainContent.labels,
              ...brandedContent.labels,
            },
          },
          {
            otherItems: {
              ...mainContent.otherItems,
              ...brandedContent.otherItems,
            },
          },
          {
            footerItems: {
              ...mainContent.footerItems,
              ...brandedContent.footerItems,
            },
          },
          {
            footerButtons: {
              ...mainContent.footerButtons,
              ...brandedContent.footerButtons,
            },
          },
        ];
        setDataSet(thisContent);
        setJoints({
          ankle: data[0].ankle ? true : false,
          elbow: data[0].elbow ? true : false,
        });
      });
  }, [pageSet, config, branded]);

  //FUNCTIONS, CONSTS
  const changePage = useCallback(
    function changePage(newPage: PageType, pageIn?: string) {
      postPageView(
        newPage.page,
        trackPageView,
        pageIn ? pageIn : pageSet || "knee"
      );

      const newArr = [...page];
      newArr.unshift({
        page: newPage.page,
        visible: true,
        transitionFrame: newPage.transitionFrame,
        animateOut: newPage.animateOut,
        backEvent: newPage.backEvent,
      });
      setPage(newArr.slice(0, 2));
      setThemeNumber(newPage.themeNumber || 0);
    },
    [page, trackPageView, pageSet]
  );

  //Determines which page to progress to based on the disclaimer and HCP env var settings

  function changeVariablePage(thisPage: Page) {
    if (thisPage === Page.Hcp) {
      changePage({
        page: Page.One,
        visible: true,
        transitionFrame: 2,
      });
    }
    if (thisPage === Page.Disclaimer) {
      if (!showHCP) {
        changePage({
          page: Page.One,
          visible: true,
          transitionFrame: 2,
        });
      } else if (showHCP) {
        changePage({
          page: Page.Hcp,
          visible: true,
          transitionFrame: 2,
        });
      }
    }
  }

  function changeParentPage(page: string) {
    setPageSet(page);
    changePage({ page: Page.Knee, visible: true, transitionFrame: 0 }, page);
  }

  if (!dataSet || !config) return null;

  return (
    //CMS Wrapper, can be disabled by setting enabled to false
    <CMS
      authReq={false}
      dataInJSON={dataSet}
      devMode={false}
      theme={
        [primaryTheme, themeColorsElocta, themeColorsAlprolix][themeNumber]
      }
      enabled={cmsEnabled}
    >
      {(_, cmsData, editButton, cmsIsEnabled) => {
        return (
          <ComponentContext.Provider
            value={{
              homeButton: homeButtonVisible,
              setHomeButton: setHomeButtonVisible,
              selectedJoint: pageSet,
              setAnkleKnee: setPageSet,
            }}
          >
            <DeviceContext.Provider value={deviceType}>
              <ThemeContext.Provider
                value={{
                  theme: [primaryTheme, themeColorsElocta, themeColorsAlprolix][
                    themeNumber
                  ],
                }}
              >
                <ContentContext.Provider
                  value={{
                    cmsData: cmsData,
                    editButton: (file: string, type: string) =>
                      editButton(file, type),
                    cmsIsEnabled: cmsIsEnabled,
                  }}
                >
                  <COMPONENTS.LoaderSobi visible={loaderSobi} />
                  {!loaderSobi && (
                    <FullCentralWrapper>
                      <COMPONENTS.RotateScreen
                        theme={primaryTheme}
                        shouldRender={isMobilePortrait}
                      />
                      <COMPONENTS.ResizeScreen
                        shouldRender={
                          !isMobilePortrait && (isTooSmall || isTooShort)
                        }
                      />
                      <COMPONENTS.MenuMobile
                        menuItems={{
                          back: {
                            file: "back",
                            label: cmsData.otherItems.back.label,
                            id: Page.Landing,
                          },
                          ...cmsData.menu,
                        }}
                        page={page[0]}
                        setMenuPage={changePage}
                        setDeviceType={(type) => setDeviceType(type)}
                        shouldRender={
                          deviceType === "mobile" && page[0].page !== Page.One
                        }
                      />
                      <COMPONENTS.Menu
                        menuItems={cmsData.menu}
                        page={page[0]}
                        setMenuPage={changePage}
                        visible={
                          page[0].page !== Page.One &&
                          page[0].page !== Page.Hcp &&
                          page[0].page !== Page.Disclaimer
                        }
                        isMobile={deviceType === "mobile"}
                        setDeviceType={(type) => setDeviceType(type)}
                        shouldRender={
                          deviceType !== "mobile" &&
                          page[0].page !== Page.One &&
                          page[0].page !== Page.Hcp &&
                          page[0].page !== Page.Disclaimer
                        }
                      />
                      <COMPONENTS.WarningBar />
                      <Elocta
                        thisPage={Page.Elocta}
                        pageArray={page}
                        changePage={changePage}
                      />
                      <Alprolix
                        thisPage={Page.Alprolix}
                        pageArray={page}
                        changePage={changePage}
                      />
                      {showHCP && (
                        <HCP
                          thisPage={Page.Hcp}
                          pageArray={page}
                          changePage={changeVariablePage}
                          setDeviceType={(type) => setDeviceType(type)}
                          isMobile={deviceType === "mobile"}
                          branded={branded}
                        />
                      )}
                      {showDisclaimer && (
                        <Disclaimer
                          thisPage={Page.Disclaimer}
                          pageArray={page}
                          changePage={changeVariablePage}
                          setDeviceType={(type) => setDeviceType(type)}
                          isMobile={deviceType === "mobile"}
                          branded={branded}
                        />
                      )}
                      <Splash
                        thisPage={Page.One}
                        pageArray={page}
                        changePage={changePage}
                        setDeviceType={(type) => setDeviceType(type)}
                        isMobile={deviceType === "mobile"}
                        branded={branded}
                      />
                      <Landing
                        thisPage={Page.Landing}
                        pageArray={page}
                        changePage={changePage}
                        setPageSet={changeParentPage}
                        joints={joints}
                      />
                      {pageSet === "knee" ? (
                        <KneePages
                          page={page}
                          changePage={changePage}
                          deviceType={deviceType}
                          setDeviceType={setDeviceType}
                          loaderSobi={loaderSobi}
                          branded={branded}
                          cmsData={cmsData}
                        />
                      ) : pageSet === "ankle" ? (
                        <AnklePages
                          page={page}
                          changePage={changePage}
                          deviceType={deviceType}
                          setDeviceType={setDeviceType}
                          loaderSobi={loaderSobi}
                          branded={branded}
                          cmsData={cmsData}
                        />
                      ) : (
                        <ElbowPages
                          page={page}
                          changePage={changePage}
                          deviceType={deviceType}
                          setDeviceType={setDeviceType}
                          loaderSobi={loaderSobi}
                          branded={branded}
                          cmsData={cmsData}
                        />
                      )}
                      <COMPONENTS.Footers
                        deviceType={deviceType}
                        page={page}
                        cmsData={cmsData}
                        editButton={editButton}
                        branded={branded}
                      />
                    </FullCentralWrapper>
                  )}
                </ContentContext.Provider>
              </ThemeContext.Provider>
            </DeviceContext.Provider>
          </ComponentContext.Provider>
        );
      }}
    </CMS>
  );
}
