import React, { useMemo } from "react";
import { Frames } from "../../components";
import { Page } from "../../types";
import { arthropathySubclinical } from "../../data/elbow/arthropathySubclinical";
import { RightMenuOne } from "../../menu/RightMenuOne";
import { LeftMenuOne } from "../../menu/LeftMenuOne";
import { PageType } from "../../types";
import { getTrackImages } from "../../utils/getTrackImages";
import { Frames360New } from "../../components/Frames360New";

import { shouldRender, shouldFadeOut } from "../../utils/fadeAndVisible";
import { Fade } from "../../components";

export function ArthropathySubclinical(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.ArthropathySubclinical,
        pageArray[0].page === Page.ArthropathySubclinical
          ? pageArray[0].animateOut
          : false,
      ],

      [pageArray[0].page === Page.ArthropathySubclinical360],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.ArthropathySubclinical;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => arthropathySubclinical(),
                changePage
              )}
              animateOut={memoProps[0][1]}
              storyboard={arthropathySubclinical()}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathySubclinical}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.ArthropathySubclinical}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    visible: true,
                    transitionFrame: -1,
                    animateOut: false,
                  }),
                targetRotation: 0,
              }}
              id="arthropathy-subclinical"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.ArthropathySubclinical360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[1][0]}
              config={{
                file: "Elbow_ArthExternal_Zoom",
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: "elbow/arthropathyExternal",
              }}
              external={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathySubclinical360}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.ArthropathySubclinical,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
