import React, { useMemo } from "react";
import { Frames } from "../../components";
import { Page } from "../../types";
import {
  storyboardClinicalBleed,
  storyboardClinicalBleedLanding,
} from "../../data/knee/clinicalBleed";
import { RightMenuOne } from "../../menu/RightMenuOne";
import { LeftMenuOne } from "../../menu/LeftMenuOne";
import { PageType } from "../../types";
import { getTrackImages } from "../../utils/getTrackImages";
import { shouldRender, shouldFadeOut } from "../../utils/fadeAndVisible";
import { Fade } from "../../components";

export function ClinicalBleed(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
  cmsData: any;
}) {
  const { changePage, pageArray, cmsData } = props;

  const memoProps: any = useMemo(() => {
    return [
      [pageArray[0].page === Page.ClinicalBleed, pageArray[0].animateOut],
      [pageArray[0].page === Page.ClinicalBleedAnimation],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, Page.ClinicalBleed)}
            shouldRender={shouldRender(pageArray, Page.ClinicalBleed)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardClinicalBleedLanding(1, cmsData),
                changePage
              )}
              animateOut={memoProps[0][1]}
              storyboard={storyboardClinicalBleedLanding(1, cmsData)}
              isVideo={true}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.ClinicalBleed} />
              }
              leftMenu={
                <LeftMenuOne setPage={changePage} page={Page.ClinicalBleed} />
              }
              id="clinical-bleed"
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, cmsData])}

      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, Page.ClinicalBleedAnimation)}
            shouldRender={shouldRender(pageArray, Page.ClinicalBleedAnimation)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardClinicalBleed(24, cmsData),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={storyboardClinicalBleed(24, cmsData)}
              isVideo={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ClinicalBleedAnimation}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.ClinicalBleedAnimation}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.ClinicalBleed,
                  visible: true,
                  transitionFrame: 0,
                  animateOut: true,
                })
              }
              id="clinical-bleed-anim"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, cmsData])}
    </>
  );
}
