import { StoryboardType } from "../../../types";

export const synovitis = () => {
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  const storyboardArr: StoryboardType[] = [];
  storyboardArr.push({
    _id: 1154,
    img: `${appAssets}/knee/clinicalBleedAnim/afterBleed.jpg`,
  });
  return storyboardArr;
};
