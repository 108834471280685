import React from "react";
import { BackButtonStyle } from "../styles";
import back from "../../assets/icons/home.svg";
import { ContentContext, ComponentContext, ThemeContext } from "../../context";
import "./backButton.css";

interface BackButtonProps {
  onClickEvent: () => void;
  visible: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ onClickEvent, visible }) => {
  if (!visible) return null;

  return (
    <ComponentContext.Consumer>
      {(componentContext) => {
        return (
          <ThemeContext.Consumer>
            {({ theme }) => (
              <ContentContext.Consumer>
                {({ cmsData, editButton }) => {
                  const { selectedJoint } = componentContext;
                  const jointPosition: [number, number] =
                    selectedJoint === "knee"
                      ? [106, 66]
                      : selectedJoint === "ankle"
                      ? [120, 52]
                      : [64, 44];

                  return (
                    <BackButtonStyle>
                      {editButton(cmsData.otherItems.back.file, "otherItems")}
                      <img
                        src={back}
                        style={{
                          width: 126,
                        }}
                        alt={cmsData.otherItems.back.label}
                        onClick={() => onClickEvent()}
                      />
                      {componentContext.selectedJoint && (
                        <div
                          className="back-button-hotspot"
                          style={{
                            background: theme.brand.primary,
                            top: jointPosition[0],
                            left: jointPosition[1],
                          }}
                        ></div>
                      )}
                      <h6 style={{ color: "#fff", margin: 4, fontWeight: 300 }}>
                        {cmsData.otherItems.back.label}
                      </h6>
                    </BackButtonStyle>
                  );
                }}
              </ContentContext.Consumer>
            )}
          </ThemeContext.Consumer>
        );
      }}
    </ComponentContext.Consumer>
  );
};

export default BackButton;
