import { StoryboardType } from "../../../types";

export const storyboardSubclinicalBleed = (
  maxRotation: number,
  quality: string | null
) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    const frameNumber =
      i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

    storyboardArr.push({
      _id: i,
      img: `${appAssets}/knee/subclinicalBleed/${
        quality ? quality : ""
      }KneeBleed_SubClinical_${frameNumber}_Ultra.jpg`,
      video: `${appAssets}/knee/kneeBleedSubclinical.mp4`,
      allowControls: false,
    });
  }
  return storyboardArr;
};
