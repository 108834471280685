import { StoryboardType } from "../../../types";

export const storyboardJointInMotion = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  for (let i = 0; i < maxRotation - 1; i++) {
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/knee/kneeBend/KneeBend_Bend_${1}_Ultra.jpg`,
      subtitle: "HealthJointSubtitles",
      video: `${appAssets}/knee/kneeBend.mp4`,
      allowControls: true,
    });
  }
  return storyboardArr;
};
