import React, { useRef, useEffect, Suspense } from "react";
import { ImageJSONType } from "../types";
import { FullCentralWrapper } from "../styles";
import {
  FrameImageNew,
  CloseButton,
  Subtitle,
  BackButton,
  RotationBar,
  CircleComponent,
} from ".";
import { Canvas } from "@react-three/fiber";
import Model from "./Model";
import {
  mouseDownEv,
  touchStartEv,
  mouseUpEv,
  touchEndEv,
  mouseOutEv,
  mouseMoveEv,
  touchMoveEv,
} from "../utils/mouseEvents";
import { throttle } from "../utils/throttle";
import { StoryboardType, Component360Type } from "../types";
import { loadImage } from "../utils/loadImage";
import { backEvent } from "../utils/backEvent";
import { DeviceContext, ComponentContext, ContentContext } from "../context";

export function Frames(props: {
  images: ImageJSONType[];
  storyboard: StoryboardType[];
  model?: string;
  isVideo?: boolean;
  autoAnimate?: boolean;
  rightMenu?: JSX.Element;
  leftMenu?: JSX.Element;
  closeMenuEvent?: () => void;
  backMenuEvent?: { event: () => void; targetRotation: number };
  animateOut?: boolean;
  id?: string;
  rotateElement?: JSX.Element;
  is360?: boolean;
  circle?: boolean;
  transitionFrame?: number;
  visible?: boolean;
  background?: string;
  config?: Component360Type;
}) {
  //PROPS
  const {
    images,
    model,
    storyboard,
    rightMenu,
    leftMenu,
    isVideo,
    rotateElement,
    closeMenuEvent,
    backMenuEvent,
    animateOut,
    is360,
    autoAnimate,
    circle,
    transitionFrame,
    visible,
    background,
  } = props;

  //STATE
  const [animationPlaying, setAnimationPlaying] = React.useState(false);
  const [rotation, setRotation] = React.useState(0);
  const [imgsLoaded, setImgsLoaded] = React.useState<any[]>([]);

  //REFS
  const mouseDown = useRef(false);
  const isBeingControlled = useRef(false);
  const isBeingAnimated = useRef(false);
  const mouseStart = useRef({ x: 0, y: 0 });
  const storyboardRef = useRef<null | any>(null);
  const timeout = useRef<any>(undefined);
  const canvas = useRef(null);

  //CONSTS
  const maxRotation = storyboard.length - 1;

  //FUNCTIONS
  function hotspotEvent(targetRotation: number, onClickEvent: () => void) {
    isBeingAnimated.current = true;
    isBeingControlled.current = false;
    onClickEvent();
  }

  //EFFECTS
  useEffect(() => {
    if (!storyboardRef.current) {
      storyboardRef.current = storyboard;
    }
  }, [storyboardRef, storyboard]);

  useEffect(() => {
    if (!isVideo) {
      if (
        storyboardRef &&
        storyboardRef.current &&
        storyboardRef.current.video
      ) {
        setImgsLoaded(["url"]);
      } else {
        Promise.all(images.map((image) => loadImage(image)))
          .then((url) => {
            setImgsLoaded(url);
          })
          .catch((err) => console.log("Failed to load images", err));
      }
    } else {
      setImgsLoaded([""]);
    }
  }, [images, storyboardRef, isVideo]);

  useEffect(() => {
    if (animationPlaying && rotation === maxRotation) {
      setAnimationPlaying(false);
    }
  }, [rotation, animationPlaying, maxRotation]);

  useEffect(() => {
    if (!transitionFrame) {
      setRotation(transitionFrame || 0);
    }
  }, [transitionFrame]);

  useEffect(() => {
    if (imgsLoaded.length > 0) {
      if (animateOut) {
        const interval = setInterval(() => {
          setRotation((rotations) => {
            rotations <= 0 && clearInterval(interval);
            return rotations ? rotations - 1 : 0;
          });
        }, 10);
        return () => clearInterval(interval);
      }
    }
  }, [imgsLoaded, animateOut]);

  if (!storyboardRef.current) {
    return null;
  }

  return (
    <DeviceContext.Consumer>
      {(device) => {
        const isMobile = device === "mobile";
        return (
          <>
            <ComponentContext.Consumer>
              {(componentContext) => {
                return (
                  componentContext.homeButton && (
                    <BackButton
                      onClickEvent={
                        backMenuEvent
                          ? () => {
                              backEvent(
                                backMenuEvent.event,
                                isBeingAnimated,
                                isBeingControlled
                              );
                            }
                          : () => null
                      }
                      visible={backMenuEvent && !isMobile ? true : false}
                    />
                  )
                );
              }}
            </ComponentContext.Consumer>
            <FullCentralWrapper
              style={{ background: background ? background : "" }}
            >
              <CircleComponent isVisible={circle || false} />
              <CloseButton
                isVisible={closeMenuEvent ? true : false}
                onClickEvent={
                  closeMenuEvent
                    ? () => {
                        backEvent(
                          closeMenuEvent,
                          isBeingAnimated,
                          isBeingControlled
                        );
                      }
                    : () => null
                }
              />
              {leftMenu}
              {rightMenu}
              {is360 && rotateElement && rotateElement}
              {!isVideo && storyboardRef.current.length > 1 && (
                <RotationBar
                  setRotation={setRotation}
                  rotation={rotation}
                  maxRotation={maxRotation}
                  direction="horizontal"
                />
              )}

              {model && (
                <ContentContext.Consumer>
                  {({ cmsData, editButton }) => {
                    return (
                      <div
                        style={{ position: "absolute", left: 10, bottom: 100 }}
                      >
                        {editButton &&
                          storyboardRef.current[Math.floor(rotation)]
                            .hotspots &&
                          storyboardRef.current[Math.floor(rotation)].hotspots
                            .length > 0 &&
                          storyboardRef.current[
                            Math.floor(rotation)
                          ].hotspots.map((item: any, index: number) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  zIndex: 100,
                                  height: 40,
                                }}
                                key={`edit_${index}`}
                              >
                                {editButton(item.cmsId, "labels")}
                              </div>
                            );
                          })}
                        <Canvas
                          ref={canvas}
                          style={{
                            width: "100%",
                            height: "100vh",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                          }}
                          gl={{ preserveDrawingBuffer: true }}
                          camera={{ fov: 45, position: [0, 0, 28] }}
                          onMouseDown={(e: any) =>
                            storyboardRef.current[0].allowControls &&
                            mouseDownEv
                              ? mouseDownEv(e, mouseDown, mouseStart)
                              : null
                          }
                          onTouchStart={(e: any) =>
                            storyboardRef.current[0].allowControls &&
                            touchStartEv
                              ? throttle(
                                  () => touchStartEv(e, mouseDown, mouseStart),
                                  timeout
                                )
                              : null
                          }
                          onMouseUp={() =>
                            storyboardRef.current[0].allowControls && mouseUpEv
                              ? mouseUpEv(mouseDown)
                              : null
                          }
                          onTouchEnd={() =>
                            storyboardRef.current[0].allowControls && touchEndEv
                              ? touchEndEv(mouseDown)
                              : null
                          }
                          onMouseOut={() =>
                            storyboardRef.current[0].allowControls && mouseOutEv
                              ? mouseOutEv(mouseDown, mouseStart)
                              : null
                          }
                          onMouseMove={(e: any) => {
                            throttle(
                              () =>
                                mouseMoveEv(
                                  e,
                                  () => null,
                                  setRotation,
                                  mouseDown,
                                  mouseStart,
                                  maxRotation
                                ),
                              timeout
                            );
                          }}
                          onTouchMove={(e: any) => {
                            touchMoveEv(
                              e,
                              mouseStart,
                              maxRotation,
                              setRotation,
                              () => null
                            );
                          }}
                        >
                          <Suspense fallback={null}>
                            <group
                              position={
                                storyboardRef.current[Math.floor(rotation)].pos
                              }
                              rotation={
                                storyboardRef.current[Math.floor(rotation)].rot
                              }
                              scale={
                                storyboardRef.current[Math.floor(rotation)]
                                  .scale
                              }
                            >
                              <Model
                                model={model}
                                hotspots={
                                  storyboardRef.current[Math.floor(rotation)]
                                    .hotspots
                                }
                                hotspotEvent={hotspotEvent}
                                cmsData={cmsData}
                              />
                            </group>
                          </Suspense>
                        </Canvas>
                      </div>
                    );
                  }}
                </ContentContext.Consumer>
              )}

              <FrameImageNew
                images={imgsLoaded}
                rotation={rotation}
                setRotation={setRotation}
                isVideo={isVideo}
                storyboardRef={storyboardRef.current}
                autoAnimate={autoAnimate}
                visible={visible}
              />

              <Subtitle
                fileName={storyboardRef.current[Math.floor(rotation)].subtitle}
                isVisible={storyboardRef.current[Math.floor(rotation)].subtitle}
              />
            </FullCentralWrapper>
          </>
        );
      }}
    </DeviceContext.Consumer>
  );
}
