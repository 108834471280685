import { Vector3, Euler } from "three";
import { StoryboardType } from "../../../types";

export const storyboard360 = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  for (let i = 0; i < maxRotation; i++) {
    storyboardArr.push({
      _id: i,
      img: ``,
      allowControls: false,
      loop: true,
      bg: "#454545",
      pos: new Vector3(0, 0, 0),
      rot: new Euler(0, 0, 0),
      scale: 1,
      hotspots: [
        {
          cmsId: "femur",
          opacity: 1,
          pos: [0.08, 6.43, 0.5],
          label: "Femur",
        },
        {
          cmsId: "patella",
          opacity: i > 12 && i < 46 ? 0 : 1,
          pos: [0.12, 2.5, 2],
          label: "Patella",
        },
        {
          cmsId: "tibia",
          opacity: 1,
          pos: [0.12, -2.57, 0.5],
          label: "Tibia",
        },
      ],
      video: ``,
    });
  }

  return storyboardArr;
};
