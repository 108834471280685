import { Page } from "../types";

export function postEvent(
  event: string,
  eventCategory: string,
  title: string,
  url: string,
  siteName: string
) {
  //@ts-ignore
  _mtm.push({
    event: event,
    title: title,
    url: url,
    siteName: siteName,
  });
}

export function pageToObject(joint?: string) {
  const pageToUrl: any = {};
  pageToUrl[Page.Version] = { title: "Version", url: "/" };
  pageToUrl[Page.Landing] = { title: "Landing Page", url: "/" };
  pageToUrl[Page.Hcp] = {
    title: " Hcp",
    url: "/",
  };
  pageToUrl[Page.Disclaimer] = {
    title: " Disclaimer",
    url: "/",
  };
  pageToUrl[Page.One] = {
    title: "Splash",
    url: "/",
  };
  pageToUrl[Page.Knee] = {
    title: "Healty Knee Joint",
    url: `${joint}/healthy-joint`,
  };
  pageToUrl[Page.Knee360] = {
    title: "Knee 360",
    url: `${joint}/healthy-360`,
  };
  pageToUrl[Page.Knee360External] = {
    title: "Knee 360 External",
    url: `${joint}/healthy-360-external`,
  };
  pageToUrl[Page.Ankle] = { title: "Ankle", url: "ankle/healthy-ankle" };
  pageToUrl[Page.HealthyJoint] = {
    title: "Healthy Knee Joint",
    url: `${joint}/healthy-joint`,
  };
  pageToUrl[Page.ClinicalBleed] = {
    title: "Clinical Bleed",
    url: `${joint}/clinical-bleed`,
  };
  pageToUrl[Page.ClinicalBleedAnimation] = {
    title: "Clinical Bleed Animation",
    url: `${joint}/clinical-bleed/animation`,
  };
  pageToUrl[Page.AfterTheBleed] = {
    title: "After The Bleed",
    url: `${joint}/clinical-bleed/after-bleed`,
  };
  pageToUrl[Page.ClinicalBleedAfterBleed360] = {
    title: "After The Bleed 360",
    url: `${joint}/clinical-bleed/after-bleed-360`,
  };
  pageToUrl[Page.AfterTheBleedExternal] = {
    title: "After The Bleed External",
    url: `${joint}/clinical-bleed/after-bleed-external`,
  };
  pageToUrl[Page.AfterBleed360External] = {
    title: "After The Bleed External 360",
    url: `${joint}/clinical-bleed/after-bleed-external-360`,
  };
  pageToUrl[Page.ImpactOfSublclinicalBleeds] = {
    title: "Impact of Subclinical Bleed",
    url: `${joint}/clinical-bleed/impact-of-subclinical-bleed`,
  };
  pageToUrl[Page.ProtectingJointHealth] = {
    title: "Protecting Joint Health",
    url: "/protecting-joint-health",
  };
  pageToUrl[Page.Elocta] = {
    title: "Elocta",
    url: "/elocta",
  };
  pageToUrl[Page.Alprolix] = {
    title: "Alprolix",
    url: "/alprolix",
  };
  pageToUrl[Page.Home] = {
    title: "Home",
    url: "/home",
  };
  pageToUrl[Page.JointInMotion] = {
    title: "Joint In Motion",
    url: `${joint}/joint-in-motion`,
  };
  pageToUrl[Page.Synovitis] = {
    title: "Synovitis",
    url: `${joint}/clinical-bleed/synovitis`,
  };
  pageToUrl[Page.SynovitisAnimation] = {
    title: "Synovitis Animation",
    url: `${joint}/clinical-bleed/synovitis-animation`,
  };
  pageToUrl[Page.Synovitis360] = {
    title: "Synovitis360",
    url: `${joint}/clinical-bleed/synovitis-360`,
  };
  pageToUrl[Page.SynovitisPostAnimation] = {
    title: "Synovitis Post Animation",
    url: `${joint}/clinical-bleed/synovitis-post-animation`,
  };
  pageToUrl[Page.SynovitisExternal] = {
    title: "Synovitis External",
    url: `${joint}/clinical-bleed/synovitis-external`,
  };
  pageToUrl[Page.Synovitis360External] = {
    title: "Synovitis 360 External",
    url: `${joint}/clinical-bleed/synovitis-360-external`,
  };
  pageToUrl[Page.Arthropathy] = {
    title: "Arthropathy",
    url: `${joint}/clinical-bleed/arthropathy`,
  };
  pageToUrl[Page.ArthropathyAnimation] = {
    title: "Arthropathy Animation",
    url: `${joint}/clinical-bleed/arthropathy-animation`,
  };
  pageToUrl[Page.ArthropathyPost] = {
    title: "Arthropathy Post",
    url: `${joint}/clinical-bleed/arthropathy-post`,
  };
  pageToUrl[Page.Arthropathy360] = {
    title: "Arthropathy 360",
    url: `${joint}/clinical-bleed/arthropathy-360`,
  };
  pageToUrl[Page.ArthropathyExternal] = {
    title: "Arthropathy External",
    url: `${joint}/clinical-bleed/arthropathy-external`,
  };
  pageToUrl[Page.ArthropathyExternal360] = {
    title: "Arthropathy External 360",
    url: `${joint}/clinical-bleed/arthropathy-external-360`,
  };
  pageToUrl[Page.SubclinicalBleed] = {
    title: "Subclinical Bleed",
    url: `${joint}/subclinical-bleed`,
  };
  pageToUrl[Page.SubclinicalBleedAnimation] = {
    title: "Subclinical Bleed Animation",
    url: `${joint}/subclinical-bleed/animation`,
  };
  pageToUrl[Page.SubclinicalAfterBleed] = {
    title: "Subclinical After Bleed",
    url: `${joint}/subclinical-bleed/after-bleed`,
  };
  pageToUrl[Page.SubclinicalExterior] = {
    title: "Subclinical Exterior",
    url: `${joint}/subclinical-bleed/exterior`,
  };

  pageToUrl[Page.AfterTheBleedSubclinical] = {
    title: "AfterTheBleedSubclinical",
    url: `${joint}/subclinical-bleed/after-the-bleed`,
  };

  pageToUrl[Page.AfterBleedAnimation] = {
    title: "AfterBleedAnimation",
    url: `${joint}/after-the-bleed/animation`,
  };

  pageToUrl[Page.AfterBleedExternalSubclinical] = {
    title: "After Bleed External Subclinical",
    url: `${joint}/subclinical/after-the-bleed-external`,
  };

  pageToUrl[Page.AfterBleed360Subclinical] = {
    title: "After Bleed 360 Subclinical",
    url: `${joint}/subclinical/after-the-bleed-360`,
  };

  pageToUrl[Page.AfterBleed360SubclinicalExternal] = {
    title: "After Bleed 360 Subclinical External",
    url: `${joint}/subclinical/after-the-bleed-external-360`,
  };
  pageToUrl[Page.SynovitisSubclinical] = {
    title: " Synovitis Subclinical",
    url: `${joint}/subclinical/synovitis`,
  };
  pageToUrl[Page.SynovitisSubclinical360] = {
    title: " Synovitis Subclinical360",
    url: `${joint}/subclinical/synovitis-360`,
  };
  pageToUrl[Page.ArthropathySubclinical] = {
    title: " Arthropathy Subclinical",
    url: `${joint}/subclinical/arthropathy`,
  };
  pageToUrl[Page.ArthropathySubclinical360] = {
    title: " Arthropathy Subclinical360",
    url: `${joint}/subclinical/arthropathy-360`,
  };
  pageToUrl[Page.AnkleVersion] = {
    title: " Ankle Version",
    url: "ankle",
  };
  pageToUrl[Page.AnkleLanding] = {
    title: " Ankle Landing",
    url: "ankle/landing",
  };
  pageToUrl[Page.AnkleKnee] = {
    title: " Ankle Knee",
    url: "ankle/landing",
  };
  pageToUrl[Page.AnkleKnee360] = {
    title: " Ankle Knee360",
    url: "ankle/healthy-360",
  };
  pageToUrl[Page.AnkleKnee360External] = {
    title: " Ankle Knee360 External",
    url: "ankle/healthy-external-360",
  };
  pageToUrl[Page.AnkleAnkle] = {
    title: " Ankle Ankle",
    url: "ankle/healthy",
  };
  pageToUrl[Page.AnkleHealthyJoint] = {
    title: " Ankle Healthy Joint",
    url: "ankle/healthy",
  };
  pageToUrl[Page.AnkleClinicalBleed] = {
    title: " Ankle Clinical Bleed",
    url: "ankle/clinical-bleed/360",
  };
  pageToUrl[Page.AnkleClinicalBleedAnimation] = {
    title: " Ankle Clinical Bleed Animation",
    url: "ankle/clinical-bleed/animation",
  };
  pageToUrl[Page.AnkleClinicalBleedAfterBleed360] = {
    title: " Ankle Clinical Bleed After Bleed360",
    url: "ankle/clinical-bleed/after-the-bleed-360",
  };
  pageToUrl[Page.AnkleAfterTheBleed] = {
    title: " Ankle After The Bleed",
    url: "ankle/clinical-bleed/after-the-bleed",
  };

  pageToUrl[Page.AnkleAfterBleed360External] = {
    title: " Ankle After Bleed360 External",
    url: "ankle/clinical-bleed/after-the-bleed-external-360",
  };
  pageToUrl[Page.AnkleImpactOfSublclinicalBleeds] = {
    title: " Ankle Impact Of Sublclinical Bleeds",
    url: "ankle/impact-of-subclinical-bleeds",
  };
  pageToUrl[Page.AnkleProtectingJointHealth] = {
    title: " Ankle Protecting Joint Health",
    url: "ankle/impact-of-subclinical-bleeds",
  };
  pageToUrl[Page.AnkleElocta] = {
    title: " Ankle Elocta",
    url: "",
  };
  pageToUrl[Page.AnkleAlprolix] = {
    title: " Ankle Alprolix",
    url: "",
  };
  pageToUrl[Page.AnkleHome] = {
    title: " Ankle Home",
    url: "",
  };
  pageToUrl[Page.AnkleOne] = {
    title: " Ankle One",
    url: "",
  };
  pageToUrl[Page.AnkleJointInMotion] = {
    title: " Ankle Joint In Motion",
    url: "ankle/joint-in-motion",
  };
  pageToUrl[Page.AnkleSynovitis] = {
    title: " Ankle Synovitis",
    url: "ankle/synovitis",
  };
  pageToUrl[Page.AnkleSynovitisAnimation] = {
    title: " Ankle Synovitis Animation",
    url: "ankle/synovitis-animation",
  };
  pageToUrl[Page.AnkleSynovitis360] = {
    title: " Ankle Synovitis360",
    url: "ankle/synovitis-360",
  };
  pageToUrl[Page.AnkleSynovitisPostAnimation] = {
    title: " Ankle Synovitis Post Animation",
    url: "ankle/synovitis-post-animation",
  };
  pageToUrl[Page.AnkleSynovitisExternal] = {
    title: " Ankle Synovitis External",
    url: "ankle/synovitis-external",
  };
  pageToUrl[Page.AnkleSynovitis360External] = {
    title: " Ankle Synovitis360 External",
    url: "ankle/synovitis-external-360",
  };
  pageToUrl[Page.AnkleArthropathy] = {
    title: " Ankle Arthropathy",
    url: "ankle/arthropathy",
  };
  pageToUrl[Page.AnkleArthropathyAnimation] = {
    title: " Ankle Arthropathy Animation",
    url: "ankle/arthropathy-animation",
  };
  pageToUrl[Page.AnkleArthropathyPost] = {
    title: " Ankle Arthropathy Post",
    url: "ankle/post-arthropathy",
  };
  pageToUrl[Page.AnkleArthropathy360] = {
    title: " Ankle Arthropathy360",
    url: "ankle/arthropathy-360",
  };
  pageToUrl[Page.AnkleArthropathyExternal] = {
    title: " Ankle Arthropathy External",
    url: "ankle/arthropathy-external",
  };
  pageToUrl[Page.AnkleArthropathyExternal360] = {
    title: " Ankle Arthropathy External360",
    url: "ankle/arthropathy-external-360",
  };
  pageToUrl[Page.AnkleSubclinicalBleed] = {
    title: " Ankle Subclinical Bleed",
    url: "ankle/subclinical-bleed",
  };
  pageToUrl[Page.AnkleSubclinicalBleedAnimation] = {
    title: " Ankle Subclinical Bleed Animation",
    url: "ankle/subclinical-bleed/animation",
  };
  pageToUrl[Page.AnkleSubclinicalAfterBleed] = {
    title: " Ankle Subclinical After Bleed",
    url: "ankle/subclinical-bleed/after-the-bleed",
  };
  pageToUrl[Page.AnkleSubclinicalExterior] = {
    title: " Ankle Subclinical Exterior",
    url: "ankle/subclinical-bleed/exterior",
  };
  pageToUrl[Page.AnkleAfterTheBleedSubclinical] = {
    title: " Ankle After The Bleed Subclinical",
    url: "ankle/subclinical-bleed/after-the-bleed",
  };
  pageToUrl[Page.AnkleAfterBleedAnimation] = {
    title: " Ankle After Bleed Animation",
    url: "ankle/after-the-bleed/animation",
  };
  pageToUrl[Page.AnkleAfterBleedExternalSubclinical] = {
    title: " Ankle After Bleed External Subclinical",
    url: "ankle/subclinical/after-the-bleed-external",
  };
  pageToUrl[Page.AnkleAfterBleed360Subclinical] = {
    title: " Ankle After Bleed360 Subclinical",
    url: "ankle/subclinical/after-the-bleed-360",
  };
  pageToUrl[Page.AnkleAfterBleed360SubclinicalExternal] = {
    title: " Ankle After Bleed360 Subclinical External",
    url: "ankle/subclinical/after-the-bleed-external-360",
  };
  pageToUrl[Page.AnkleSynovitisSubclinical] = {
    title: " Ankle Synovitis Subclinical",
    url: "ankle/subclinical/synovitis",
  };
  pageToUrl[Page.AnkleSynovitisSubclinical360] = {
    title: " Ankle Synovitis Subclinical360",
    url: "ankle/subclinical/synovitis-360",
  };
  pageToUrl[Page.AnkleArthropathySubclinical] = {
    title: " Ankle Arthropathy Subclinical",
    url: "ankle/subclinical/arthropathy",
  };
  pageToUrl[Page.AnkleArthropathySubclinical360] = {
    title: " Ankle Arthropathy Subclinical360",
    url: "ankle/subclinical/arthropathy-360",
  };

  return pageToUrl;
}
export function postPageView(pageIn: Page, trackPageView: any, joint: string) {
  if (pageToObject(joint)[pageIn]) {
    //@ts-ignore
    window._mtm.push({
      event: "customPageview",
      title: pageToObject(joint)[pageIn].title,
      url: pageToObject(joint)[pageIn].url
        ? pageToObject(joint)[pageIn].url
        : pageToObject(joint)[pageIn].title.replace(/\s+/g, "-").toLowerCase(),
      eventCategory: "",
      eventAction: "",
      siteName: "sweden",
    });
  } else {
  }

  //@ts-ignore
}
